<template>
  <div>
    <el-row class="menu-top">
      <el-col>
        <el-row>
          <el-col :span="6" class='iphone5-24 iphone5-6'>
            <el-row class="logo">
              <el-col :span="8" class="logo-img">
                <img :src="logoImg">
              </el-col>
              <el-col :span="16" class="logo-text">淘券小城</el-col>
            </el-row>
          </el-col>
          <el-col :span="12" class='iphone5-24 iphone5-18'>
            <el-row>
              <el-input placeholder="请输入内容和你要查询的链接" v-model="searchStr" class="search-input">
                <el-select
                  style="width:100px;"
                  v-model="selectOption"
                  slot="prepend"
                  placeholder="请选择"
                >
                  <el-option label="站内搜" value="1"></el-option>
                  <el-option label="淘宝商品链接" value="3"></el-option>
                  <el-option label="搜淘宝" value="2"></el-option>
                </el-select>
                <el-button slot="append" @click="doSearch()" icon="el-icon-search"></el-button>
              </el-input>
            </el-row>
            <el-row class="tags-box">
              <span>热门搜索：</span>
              <span v-for="(item,index) in keywordsList" :key="index">
                <span @click="getKeywordGoods(item.keyword)" class="tag">{{item.keyword}}</span>
              </span>
            </el-row>
          </el-col>
          <el-col :span="6"></el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="nav-menu-box">
  <el-col class="phone-menu">
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
     
      text-color="red"
      active-text-color="#ffd04b"
      >
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>导航</span>
        </template>
     
            <el-menu-item index="home">
            <router-link class="menu" to="/">首页</router-link>
          </el-menu-item>
          <el-menu-item index="HHotGoods">
            <router-link class="menu" to="/HHotGoods">热销榜</router-link>
          </el-menu-item>

          <el-menu-item index="HQiang">
            <router-link class="menu" to="/HQiang">抢购</router-link>
          </el-menu-item>
          <el-menu-item index="HTalent">
            <router-link class="menu" to="/HTalent">达人推荐</router-link>
          </el-menu-item>

          <el-menu-item index="hBrandGoods">
            <router-link class="menu" to="/hBrandGoods">超值大牌</router-link>
          </el-menu-item>

          <el-menu-item index="HAllGoods">
            <router-link class="menu" to="/HAllGoods">全部商品</router-link>
          </el-menu-item>
        
    
      </el-submenu>
    
    </el-menu>
  </el-col>

      <el-col :span="16" :offset="4" class="pc-menu ">
        <el-menu
          :default-active="activeMenu"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#222"
          text-color="#fff"
          active-text-color="#f1ee0b"
        >
          <el-menu-item index="home">
            <router-link class="menu" to="/">首页</router-link>
          </el-menu-item>
          <el-menu-item index="HHotGoods">
            <router-link class="menu" to="/HHotGoods">热销榜</router-link>
          </el-menu-item>

          <el-menu-item index="HQiang">
            <router-link class="menu" to="/HQiang">抢购</router-link>
          </el-menu-item>
          <el-menu-item index="HTalent">
            <router-link class="menu" to="/HTalent">达人推荐</router-link>
          </el-menu-item>

          <el-menu-item index="hBrandGoods">
            <router-link class="menu" to="/hBrandGoods">超值大牌</router-link>
          </el-menu-item>

          <el-menu-item index="HAllGoods">
            <router-link class="menu" to="/HAllGoods">全部商品</router-link>
          </el-menu-item>
          <!-- <el-menu-item index="5" disabled>消息中心</el-menu-item> -->
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
   asyncData({ store, route }) {
    let req = [
      store.dispatch("hKeywords", { page: 1, offset: 10 })
    ];
    return Promise.all(req);
  },
  computed: {
    keywordsList() {
      let hKeywords = this.$store.state.hKeywords || {};
      // console.log('hKeywords',hKeywords);
      return hKeywords;
    }
  },
  data() {
    var logo = this.$api.hostUrl + "wxAppImage/logo.jpg";
    let hostUrl = this.$api.hostUrl;
    return {
      hostUrl: hostUrl,
      logoImg: logo,
      searchStr: "",
      activeMenu: "home",
      selectOption: "1"
    };
  },
  mounted:function(){
      this.$store.dispatch("hKeywords", { page: 1, offset: 10 })
  },
  created: function() {
    this.activeMenu = this.$route.name;
    // console.log(this.$route);
  },
  // watch: {
  //   $route(to, from) {
  //     this.$router.go(0);
  //   }
  // },
  methods: {
    doSearch() {
      let str = this.searchStr;
      if (str) {
        if (this.selectOption == 1) {
          this.$router.push({ name: "HAllGoods", query: { keyword: str } });
        } else if (this.selectOption == 2) {
          this.$router.push({ name: "TGoodsList", query: { keyword: str } });
        } else if (this.selectOption == 3) {
          var regExpStr = /(?:detail.tmall.com|item.taobao.com).*(?:&|\?)id=(\d+)/;
          let arrStr = regExpStr.exec(str);
          if (arrStr[1]) {
            this.$router.push({
              name: "HGoodsDetail",
              query: { id: arrStr[1] }
            });
          } else {
            this.showMsg("请输入你要查询的链接不正确！！");
          }
        }
      } else {
        this.showMsg("请输入你要查询的内容或链接！！");
      }
    },
    getKeywordGoods(keyword) {
      // this.$router.push({ name: "HAllGoods", query: { keyword: keyword } });
      this.$router.replace({ name: "HAllGoods", query: { keyword: keyword } });
    },
    showMsg(msg) {
      const h = this.$createElement;
      this.$message({
        message: h("p", null, [
          h("span", { style: "color: red;font-size:48;" }, msg),
          h("i", { style: "color: teal;font-size:28" }, "!!")
        ])
      });
    }
  }
};
</script>