<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <!-- 热销分类商品1 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="16" class="section-title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'Home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'HAllGoods' }">全部商品</el-breadcrumb-item>
                <el-breadcrumb-item>淘宝商品搜索列表</el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
            <el-col
              :span="2"
              :offset="6"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>

        <el-row class="section-content">
          <el-col
            :span="6"
           class="goods-item iphone5-24 iphone5-12"
            v-for="goodsInfo in goodsList"
            :key="goodsInfo.index"
          >
            <el-row class="goods-box height450 com-hover">
              <a :href="goodsInfo.taobao_url" target="_blank">
                <el-col :span="24" class="goods-img h28">
                  <img v-lazy="goodsInfo.pictUrl" :key="goodsInfo.pictUrl">
                </el-col>
              </a>
              <el-col :span="24" class="goods-info-top">↑ 点击上面去淘宝 ↓ 点击下面来领券</el-col>
              <el-col :span="24" class="goods-info">
                <span @click="goDetail(goodsInfo.auctionId)">
                  <el-row class="goods-title">{{goodsInfo.title}}</el-row>
                  <el-row class="goods-price-box">
                    <el-col class="goods-price" :span="9">
                      <span class="fq-ticket">券</span>
                      <span class="fq-ticket-num">￥{{goodsInfo.couponAmount}}</span>
                    </el-col>
                    <el-col class="goods-price" :span="15" v-if="goodsInfo.couponLeftCount">
                      <span class="goods-price-title">券剩余：</span>
                      <span class="price-num">{{goodsInfo.couponLeftCount}}</span>
                    </el-col>
                  </el-row>
                  <el-row class="goods-price-box">
                    <el-col class="goods-price" :span="9" v-if="goodsInfo.itemsale">
                      <span class="price-title">月销量：</span>
                      <span class="price-num">{{goodsInfo.biz30day}}</span>
                    </el-col>
                    <el-col class="goods-price" :span="15" >
                      <span class="goods-price-title">售价:￥</span>
                      <span class="price-num">{{goodsInfo.zkPrice}}</span>
                    </el-col>
                  </el-row>
                  <el-row class="goods-price-box" v-if="goodsInfo.itemprice">
                    <el-col class="goods-price" :span="9">
                      <span class="price-num">{{goodsInfo.couponInfo}}</span>
                    </el-col>
                    <el-col class="goods-price com-del" :span="15">&nbsp;</el-col>
                  </el-row>
                  <el-row class="goods-price-box">
                    <el-col class="goods-icon" :span="6">
                      <img v-if="goodsInfo.isTbPt == '1'" :src="hostUrl+'wxAppImage/tianmao.png'">
                      <img v-if="goodsInfo.isTbPt == '0'" :src="hostUrl+'wxAppImage/taobao.png'">
                    </el-col>
                    <el-col class="goods-price" :span="18" v-if="goodsInfo.shopTitle">
                      <span class="goods-price-title">店铺:</span>
                      <span class="price-num">{{goodsInfo.shopTitle}}</span>
                    </el-col>
                  </el-row>
                </span>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <None v-show="showNone"/>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import GoodsList from "@/components/GoodsList.vue";
import None from "@/components/None.vue";
export default {
  components: {
    GoodsList,
    None
  },
  data() {
    return {
      hostUrl: this.$api.hostUrl,
      showNone: "",
      goodsList: [],
      reqStr: "",
      pageNum: 1,
      isLoading: false
    };
  },
  created: function() {
    let _this = this;
    let params = this.$route.query;
    this.$login(function() {
      if (params.keyword) {
        _this.reqStr = params.keyword;
        _this.getGoodsAndCoupon();
      }

      // window.onscroll = () => {
      //   let bottomOfWindow =
      //     document.documentElement.offsetHeight -
      //       document.documentElement.scrollTop -
      //       window.innerHeight <=
      //     200;
      //   // 距离底部200px时加载一次
      //   let isLoading = _this.isLoading;
      //   let routeName = _this.$route.name;
      //   if (bottomOfWindow && isLoading == false && routeName == 'TGoodsList') {
      //     _this.isLoading = true;
      //     _this.getGoodsAndCoupon();
      //   }
      // };
    });
  },
  methods: {
    getGoodsAndCoupon() {
      let _this = this;
      let reqStr = this.reqStr;
      let pageNum = this.pageNum;
      _this.$api.taobaoGoods(reqStr, pageNum, 30, function(res) {
        let resData = res.data.data.taobaoGoods;
        if (resData) {
          _this.goodsList = resData;
          if(resData.length == 30){
              _this.isLoading = false;
          } else {
            _this.showNone = true;
          }
          _this.pageNum++;
        } else {
          _this.showNone = true;
        }
      });
    } ,
  goDetail(id) {
    this.$router.push({ name: "HGoodsDetail", query: { id: id } });
  }
  }
};
</script>