import Vue from 'vue'
import Vuex from 'vuex'
import api from './api';

Vue.use(Vuex)


export function createStore() {
  return new Vuex.Store({

    state: {
      //menu
      hKeywords:{list:[]},
      //home
      superClassify: {list:[]},
      todayTjList: {list:[]},
      swiperList: {list:[]},
      newInfoList: {list:[]},
      todayHotGoods: {list:[]},
      //allGoods
      superClassify2: {list:[]},
      goodsCategory:{list:[]},
      allGoods: {list:[]},
      hKeywordsGoods:{list:[]},
      //hBrandDetail
      hBrandDetail: {data:{}},
      hBrandList: {list:[]},
      //hBrandsAndGoods
      hBrandsAndGoods: {list:[]},
      //HGoodsDetail
      hGoodsDetail: { data: {}},
      //hFastbuyGoods
      hFastbuyGoods:{list:[]},
      //hTalentInfo
      hTalentInfo:{list:[]},
      //hTalentDetail
      hTalentDetail:{list:[]},


      hTodayGoods: {list:[]},
      hHotGoods: {list:[]},
      hAllGoods: {list:[]},
      hBrandGoods: {list:[]},
      hWxGoods: {list:[]},
      hSpecialGoods: {list:[]},
      hSuperClassifyGoods: {list:[]},
      hSubject: {list:[]},
      hSubjectGoods: {list:[]},
      hUserLikeGoods:{list:[]},
      hGoodsArtical: {list:[]},
      hSearch:{list:[]},
      hCoupon:{list:[]},
      noticeList: {list:[]},
      todayHotList: {list:[]},
    },
    actions: {
      //home 
      superClassify({ commit }) {
        return api.hSuperClassify(1, 10, function (res) {
          if (res.data.data.hSuperClassify.super_classify_list) {
            let superClassify = res.data.data.hSuperClassify.super_classify_list;
            commit('superClassify', { superClassify })
          }
        });
      },
      todayTjList({ commit }, params) {
        return api.hTodayGoods(params.page, params.offset, function (res) {
          if (res.data.data.hTodayGoods.today_goods_list) {
            let todayTjList = res.data.data.hTodayGoods.today_goods_list;
            commit('todayTjList', { todayTjList });
          }
        });
      },
      swiperList({ commit }, params) {
        return api.NoticeList(params.type, params.page, function (res) {
          let swiperList = res.data.data.noticeList;
          if (swiperList) {
            commit('swiperList', { swiperList });
          }
        });
      },
      newInfoList({ commit }) {
        return api.hTalentInfo(0, 1, 10, function (res) {
          if (res.data.data.hTalentInfo.talent_info_list) {
            let newInfoList = res.data.data.hTalentInfo.talent_info_list.newdata;
            commit('newInfoList', { newInfoList });
          }
        });
      },
      todayHotGoods({ commit }, params) {
        return api.hTodayGoods(params.page, params.offset, function (res) {
          if (res.data.data.hTodayGoods.today_goods_list) {
            let todayHotGoods = res.data.data.hTodayGoods.today_goods_list;
            commit('todayHotGoods', { todayHotGoods });
          }
        });
      },
      //allGoods
      superClassify2({ commit }) {
        return api.hSuperClassify(1, 10, function (res) {
          if (res.data.data.hSuperClassify.super_classify_list) {
            let superClassify2 = res.data.data.hSuperClassify.super_classify_list;
            commit('superClassify2', { superClassify2 });
          }
        });
      },
      goodsCategory({ commit }, params) {
        return api.goodsCategory(params.type, function (res) {
          if (res.data.data.goodsCategory) {
            let goodsCategory = res.data.data.goodsCategory;
            commit('goodsCategory', { goodsCategory });
          }
        });
      },
      allGoods({ commit, state }, params) {
        return api.hAllGoods(params.cid, params.page, params.offset, params.sort, function (res) {
          if (res.data.data.hAllGoods) {
            let resData = res.data.data.hAllGoods;
            let page = resData.min_id;
            let allGoods = resData.all_goods_list;
            if (params.page != 1) {
              allGoods = state.allGoods.list.concat(allGoods);
            }
            commit('allGoods', { allGoods, page });
          }
        });
      },
      hKeywordsGoods({ commit, state }, params) {
        return api.hKeywordsGoods(params.keyword, params.cid, params.sort, params.page, params.offset, function (res) {
        
          if (res.data.data.hKeywordsGoods.keywords_goods_list) {
            let resData = res.data.data.hKeywordsGoods;
            let page = resData.min_id;
            let list = resData.keywords_goods_list;
            let showNone = false;
            if (params.page != 1) {
              list = state.hKeywordsGoods.list.concat(list);
            }
            console.log('{ list, page, showNone }', { list, page, showNone });
            commit('hKeywordsGoods', { list, page, showNone });
          } else {
            let showNone = true;
            let list = state.hKeywordsGoods.list;
            let page = state.hKeywordsGoods.page;
                console.log('{ list, page, showNone }', { list, page, showNone });
               commit('hKeywordsGoods', { list, page, showNone });
          }
        });
      },
      //hBrandDetail
      hBrandDetail({ commit, state }, params) {
        return api.hBrandGoods(params.brandId, params.page, params.offset, function (res) {
          if (res.data.data.hBrandGoods.brand_goods) {
            let resData = res.data.data.hBrandGoods;
            
            let page = resData.min_id;
            let brand_goods = resData.brand_goods;
            // if (params.page != 1) {
            //   list = state.hBrandDetail.list.concat(list);
            // }
            console.log("store.js---hBrandDetail:resData", resData)
            commit('hBrandDetail',  brand_goods );
          }
        });

      },
      hBrandList({ commit }, params) {
        return api.hBrandList(params.page, params.offset, function (res) {
          if (res.data.data.hBrandList.brand_list) {
            let resData = res.data.data.hBrandList;
            let page = resData.min_id;
            let list = resData.brand_list;
            commit('hBrandList', { list, page });
          }
        });

      },
      //hBrandsAndGoods
      hBrandsAndGoods({ commit }, params) {
        return api.hBrandsAndGoods(params.cid, params.page, params.offset, function (res) {
          if (res.data.data.hBrandsAndGoods.brands_goods_list) {
            let resData = res.data.data.hBrandsAndGoods;
            let page = resData.min_id;
            let list = resData.brands_goods_list;
            commit('hBrandsAndGoods', { list, page });
          }
        });

      },
      //hGoodsDetail
      hGoodsDetail({ commit }, params) {
        return api.hGoodsDetail(params.id, function (res) {
          if (res.data.data.hGoodsDetail) {
            console.log('store.hGoodsDetail',res.data.data.hGoodsDetail);
            let hGoodsDetail = res.data.data.hGoodsDetail;
            commit('hGoodsDetail', { hGoodsDetail });
          }
        });
      },
      hUserLikeGoods({ commit }, params) {
        return api.hUserLikeGoods(params.id, function (res) {
          if (res.data.data.hUserLikeGoods) {
            console.log('res.data.data.hUserLikeGoods',res.data.data.hUserLikeGoods);
            let hUserLikeGoods = res.data.data.hUserLikeGoods.user_like_goods_list || {};
            commit('hUserLikeGoods', { hUserLikeGoods });
          }
        });
      },
      hCoupon({ commit }, params) {
        return api.hCoupon(params.id, function (res) {
          if (res.data.data.hCoupon) {
            console.log('res.data.data.hCoupon',res.data.data.hCoupon);
            let hCoupon = res.data.data.hCoupon;
            commit('hCoupon', { hCoupon });
          }
        });
      },
      //hHotGoods
      hHotGoods({ commit, state }, params) {
        console.log('hHotGoods.params',params);
        return api.hHotGoods(params.cid, params.type, params.page, params.offset, function (res) {
          if (res.data.data.hHotGoods.hot_goods_list) {
            let resData = res.data.data.hHotGoods;
            let page = resData.min_id;
            let list = resData.hot_goods_list;
            if (params.page != 1) {
              list = state.hHotGoods.list.concat(list);
            }
            commit('hHotGoods', { list, page });
          } else { 

          }
        });

      },
      //hFastbuyGoods
      hFastbuyGoods({ commit },params) {
        return api.hFastbuyGoods(params.type, params.page, params.offset, function (res) {
          if (res.data.data.hFastbuyGoods) {
            let resData = res.data.data.hFastbuyGoods;
            let page = resData.min_id;
            let list = resData.fastbuy_goods_list;
            commit('hFastbuyGoods', { list, page });
          }
        });
      },
      //hTalentInfo
      hTalentInfo({ commit },params){
        return api.hTalentInfo(params.type, params.page, params.offset, function (res) {
          if (res.data.data.hTalentInfo) {
            let resData = res.data.data.hTalentInfo;
            let page = resData.min_id;
            let list = resData.talent_info_list;
            commit('hTalentInfo', { list, page });
          }
        });
      },
      //hTalentDetail
      hTalentDetail({ commit },params){
        return api.hTalentDetail(params.id,  function (res) {
          if (res.data.data.hTalentDetail) {
            let hTalentDetail = res.data.data.hTalentDetail.talent_info;
            commit('hTalentDetail', { hTalentDetail });
          }
        });
      },
      //menu
      hKeywords({ commit },params){
        return api.hKeywords(params.page, params.offset, function (res) {
          if (res.data.data.hKeywords) {
            let hKeywords = res.data.data.hKeywords;
            commit('hKeywords', { hKeywords });
          }
        });
      },





      hTodayGoods({ commit }, params) {
        return api.hTodayGoods(params.page, params.offset, function (res) {
          if (res.data.data.hTodayGoods.today_goods_list) {
            let hTodayGoods = res.data.data.hTodayGoods.today_goods_list;
            commit('hTodayGoods', { hTodayGoods });
          }
        });
      },
      todayHotList({ commit }, params) {
        api.hHotGoods(params.cid, params.sale_type, params.page, params.offset, function (res) {
          if (res.data.data.hHotGoods.hot_goods_list) {
            let todayHotList = res.data.data.hHotGoods.hot_goods_list;
            commit('todayHotList', { todayHotList });
          }
        });
      },
      hBrandGoods({ commit }) {
        api.hBrandGoods(0, 1, 10, function (res) {
          if (res.data.data.hBrandGoods.data) {
            let hBrandGoods = res.data.data.hBrandGoods.data;
            commit('hBrandGoods', { hBrandGoods });
          }
        });
      },
      hWxGoods({ commit }) {
        api.hWxGoods(1, 10, function (res) {
          if (res.data.data.hWxGoods.data) {
            let hWxGoods = res.data.data.hWxGoods.data;
            commit('hWxGoods', { hWxGoods });
          }
        });
      },
      hSpecialGoods({ commit }) {
        api.hSpecialGoods(1, 10, function (res) {
          if (res.data.data.hSpecialGoods.data) {
            let hSpecialGoods = res.data.data.hSpecialGoods.data;
            commit('hSpecialGoods', { hSpecialGoods });
          }
        });
      },
      hSuperClassifyGoods({ commit }) {
        api.hSuperClassify(1, 10, function (res) {
          if (res.data.data.hSuperClassify.data) {
            let hSuperClassifyGoods = res.data.data.hSuperClassify.data;
            commit('hSuperClassifyGoods', { hSuperClassifyGoods });
          }
        });
      },
      hSubject({ commit }) {
        api.hSubject(1, 10, function (res) {
          if (res.data.data.hSubject.data) {
            let hSubject = res.data.data.hSubject.data;
            commit('hSubject', { hSubject });
          }
        });
      },
      hSubjectGoods({ commit }) {
        api.hSubjectGoods('3734', 1, 10, function (res) {
          if (res.data.data.hSubjectGoods.data) {
            let hSubjectGoods = res.data.data.hSubjectGoods.data;
            commit('hSubjectGoods', { hSubjectGoods });
          }
        });
      },

      hGoodsArtical({ commit }) {
        api.hGoodsArtical(1, 10, function (res) {
          if (res.data.data.hGoodsArtical.data) {
            let hGoodsArtical = res.data.data.hGoodsArtical.data;
            commit('hGoodsArtical', { hGoodsArtical });
          }
        });
      },
      hSearch({ commit }) {
        api.hSearch('女裙', 1, 10, 0, 1, function (res) {
          if (res.data.data.hGoodsArtical.data) {
            let hSearch = res.data.data.hSearch.data;
            commit('hSearch', { hSearch });
          }
        });
      },
      noticeList({ commit }, params) {
        api.NoticeList(params.type, params.page, function (res) {
          let noticeList = res.data.data.noticeList;
          if (noticeList) {
            commit('setNoticeList', { noticeList });
          }
        });
      },
    },
    mutations: {
      hKeywords(state, { hKeywords }) {
        state.hKeywords = { list:hKeywords };
      },
      //home
      superClassify(state, { superClassify }) {
        state.superClassify = {list:superClassify};
      },
      todayTjList(state, { todayTjList }) {
        state.todayTjList = { list: todayTjList };
      },
      swiperList(state, { swiperList }) {
        state.swiperList = {list:swiperList};
      },
      newInfoList(state, { newInfoList }) {
        state.newInfoList = { list: newInfoList };
      },
      todayHotGoods(state, { todayHotGoods }) {
        state.todayHotGoods = { list: todayHotGoods };
      },
      //allGoods
      hTodayGoods(state, { hTodayGoods }) {
        state.hTodayGoods = { list: hTodayGoods };
        // Vue.set(state.hSuperClassify,key ,value)
      },
      allGoods(state, { allGoods, page }) {
        state.allGoods = { list: allGoods, page: page };
      },
      superClassify2(state, { superClassify2 }) {
        state.superClassify2 = { list:superClassify2 };
      },
      hKeywordsGoods(state, { list, page, showNone }) {
        state.hKeywordsGoods = { list: list, page: page, showNone:showNone };
      },
      //hBrandDetail
      hBrandDetail(state, brand_goods) {
        state.hBrandDetail = { data: brand_goods};
      },
      hBrandList(state, { list, page }) {
        state.hBrandList = { list: list, page: page };
      },
      //hBrandsAndGoods
      hBrandsAndGoods(state, { list, page }) {
        state.hBrandsAndGoods = { list: list, page: page };
      },
      //hHotGoods
      hHotGoods(state, { list, page }) {
        state.hHotGoods = { list: list, page: page };
      },
      //hFastbuyGoods
      hFastbuyGoods(state, { list, page }) {
        state.hFastbuyGoods = { list: list, page: page };
      },
      //hTalentDetail
      hTalentDetail(state, { hTalentDetail }) {
        state.hTalentDetail = { list:hTalentDetail };
      },
      //other 
      goodsCategory(state, { goodsCategory }) {
        state.goodsCategory = { list:goodsCategory };
        // Vue.set(state.hSuperClassify,key ,value)
      },
      hTalentInfo(state, { list, page }) {
        state.hTalentInfo = { list: list, page: page }
      },
      hAllGoods(state, { hAllGoods }) {
        state.hAllGoods ={list:hAllGoods};
      },
      hBrandGoods(state, { hBrandGoods }) {
        state.hBrandGoods = { list: hBrandGoods };
      },
      hWxGoods(state, { hWxGoods }) {
        state.hWxGoods = { list: hWxGoods };
      },
      hSpecialGoods(state, { hSpecialGoods }) {
        state.hSpecialGoods = { list: hSpecialGoods };
      },
      hSuperClassifyGoods(state, { hSuperClassifyGoods }) {
        state.hSuperClassifyGoods = { list: hSuperClassifyGoods };
      },
      hSubject(state, { hSubject }) {
        state.hSubject = { list:hSubject };
      },
      hSubjectGoods(state, { hSubjectGoods }) {
        state.hSubjectGoods = { list: hSubjectGoods };
      },
      hGoodsDetail(state, { hGoodsDetail }) {
        state.hGoodsDetail ={data:hGoodsDetail};
      },
      hUserLikeGoods(state, { hUserLikeGoods }) {
        state.hUserLikeGoods = { list: hUserLikeGoods };
      },
      hGoodsArtical(state, { hGoodsArtical }) {
        state.hGoodsArtical = { list: hGoodsArtical };
      },
      hSearch(state, { hSearch }) {
        state.hSearch = { list: hSearch };
      },
      hCoupon(state, { hCoupon }) {
        state.hCoupon = { data: hCoupon };
      },
      noticeList(state, { noticeList }) {
        state.noticeList = { list: noticeList };
      },
      todayHotList(state, { todayHotList }) {
        state.todayHotList = { list: todayHotList };
      }
    }
  })
}


