import Vue from 'vue';
import App from './App.vue';
import { createRouter } from './router';
// import './registerServiceWorker';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../static/css/common.less';
import VueLazyload from 'vue-lazyload';  //引入这个懒加载插件
import { createStore } from './store';
import { sync } from 'vuex-router-sync';
import api from './api';
import { parseTime } from "./date"

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$api = api;
// // 或者添加VueLazyload 选项
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('../static/img/error.jpg'),
  //loading: require('./assets/timg.gif'),
  loading: require('../static/img/timg.gif'),
  // attempt: 1
});
Vue.filter("parseTime", parseTime)

Vue.prototype.$showLoading = function () {
  const loading = this.$loading({
    lock: true,
    text: "Loading",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)"
  });
  return loading;
};

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app');
// 导出一个工厂函数，用于创建新的
// 应用程序、router 和 store 实例
export function createApp() {
  const router = createRouter()
  const store = createStore()

  // 同步路由状态(route state)到 store
  sync(store, router)
  const app = new Vue({
    router,
     store,
    render: h => h(App)
  })
  return { app, router, store }
}
