<template>
  <el-row>
    <el-col class="com-center none">
        <strong> = = 没有更多数据！= = </strong>
    </el-col>
  </el-row>
</template>

<script>
export default {};
</script>