<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <el-row class="banner">
          <el-col class="tag-box com-pd10">
            <el-row>
              <el-row>
                <el-tag class="tag-title">热门类目</el-tag>
                <el-tag v-for="(item,index) in categoryList" :key="index">
                  <span
                    @click="getGoodsListByCid(item.cat_code)"
                    :class="cid==item.cat_code? 'red' :''"
                  >{{item.cat_name}}</span>
                </el-tag>
              </el-row>
            </el-row>
          </el-col>
        </el-row>

        <!-- 商品列表 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="10" class="section-title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>热销榜</el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
            <el-col
              :span="2"
              :offset="12"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>

        <el-row class="section nav-menu">
          <el-row class="nav-menu-bg"></el-row>
          <el-col :offset="4" class="nav-sale-list" :span="16">
            <el-col :class="type==1? 'nav-menu-active' :''" :span="8" class="iphone5-24 iphone5-8">
              <span @click="getGoodsListByType(1)">实时热销榜</span>
            </el-col>
            <el-col :class="type==2? 'nav-menu-active' :''" :span="8" class="iphone5-24 iphone5-8">
              <span @click="getGoodsListByType(2)">今日热销榜</span>
            </el-col>
            <el-col :class="type==3? 'nav-menu-active' :''" :span="8" class="iphone5-24 iphone5-8">
              <span @click="getGoodsListByType(3)">昨日热销榜</span>
            </el-col>
          </el-col>
        </el-row>

        <!-- 热销分类商品1 -->
        <el-row class="section-content">
          <el-col
            :span="6"
            v-for="(item,index) in hotGoodsList"
            :key="index"
            class="goods-item iphone5-24 iphone5-12"
          >
            <GoodsList :goods-info="item" />
          </el-col>
        </el-row>
        <None v-show="showNone" />
      </el-col>
    </el-row>
  </div>
</template>


<script>
import GoodsList from "@/components/GoodsList.vue";
import None from "@/components/None.vue";
import { setTimeout } from "timers";
export default {
  asyncData({ store, route }) {
    let params = route.query;
    this.cid = params.cid || 0;
    this.type = params.type || 2;
    this.page = params.page || 1;

    let req = [
      store.dispatch("hHotGoods", {
        cid: this.cid,
        type: this.type,
        page: this.page,
        offset: 20,
      }),
      store.dispatch("goodsCategory", { type: "hcate" }),
    ];
    return Promise.all(req);
  },
  computed: {
    hotGoodsList: function () {
      let hHotGoods = this.$store.state.hHotGoods.list;
      return hHotGoods;
    },
    categoryList: function () {
      let goodsCategory = this.$store.state.goodsCategory.list;
      return goodsCategory;
    },
  },
  components: {
    GoodsList,
    None,
  },
  data() {
    return {
      hostUrl: this.$api.hostUrl,
      cid: 0,
      type: 2,
      isLoading: false,
      showNone: false,
      page: 1,
    };
  },
  created: function () {},
  mounted: function () {
    let params = this.$route.query;
    this.cid = params.cid || 0;
    this.type = params.type || "";
    this.page = params.page || 1;
    console.log("params", params);
    console.log("mounted.this.page", this.page);

    let _this = this;
    _this.getHotGoods();
    _this.getGoodsCategory();
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.offsetHeight -
            document.documentElement.scrollTop -
            window.innerHeight <=
          200;
        // 距离底部200px时加载一次
        let isLoading = _this.isLoading;
        let routeName = _this.$route.name;
        console.log(
          "aaaaaaaaaaaaaaaaaaaaaaa",
          bottomOfWindow,
          isLoading,
          routeName
        );
        if (bottomOfWindow && isLoading == false && routeName == "HHotGoods") {
          _this.isLoading = true;
          _this.getHotGoods();
        }
      };
    }
  },
  methods: {
    getHotGoods() {
      let _this = this;
      let page = this.page;
      this.isLoading = true;
      if (page == 1) {
        _this.hotGoodsList = [];
      } else {
        page = this._page;
      }

      console.log("aaaaa", {
        cid: this.cid,
        type: this.type,
        page: page,
        offset: 20,
      });
      this.$store
        .dispatch("hHotGoods", {
          cid: this.cid,
          type: this.type,
          page: page,
          offset: 20,
        })
        .then((response) => {
          this.page++;
          this.isLoading = false;
            console.log('response',this.isLoading );
        });
    },
    getGoodsCategory() {
      this.$store.dispatch("goodsCategory", { type: "hcate" }).then((response) => {
          this.isLoading = false;
        });
    },
    getGoodsListByCid(cid) {
      let type = this.type;
      this.$router.push({
        name: "HHotGoods",
        query: { cid: cid, type: type, page: 1 },
      });
    },
    getGoodsListByType(type) {
      let cid = this.cid;
      this.$router.push({
        name: "HHotGoods",
        query: { cid: cid, type: type, page: 1 },
      });
    },
  },
};
</script>