<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <el-row class="banner">
          <el-col class="tag-box com-pd10">
            <el-row>
              <el-row>
                <el-tag class="tag-title">热门类目</el-tag>
                <el-tag v-for="(item,index) in categoryList" :key="index">
                  <span
                    @click="getGoodsListByCid(item.cat_code)"
                    :class="cid==item.cat_code? 'red' :''"
                  >{{item.cat_name}}</span>
                </el-tag>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
        <!-- 热销分类商品1 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="16" class="section-title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'Home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'HAllGoods' }">全部商品</el-breadcrumb-item>
                <el-breadcrumb-item>{{keyword}}</el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
            <el-col
              :span="2"
              :offset="6"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>

        <el-row class="section-content">
          <el-col :span="6" class="banner iphone5-24 iphone5-12">
            <el-row class="cate-list-box">
              <el-tabs :tab-position="tabPosition" class="superClassify height450">
                <el-tab-pane
                  class="cat-list-scroll superClassify height450"
                  v-for="(item,index) in superClassify"
                  :key="index"
                  :label="item.main_name"
                >
                  <el-row>
                    <el-col v-for="(item2,index2) in item.data" :key="index2" class="tag-box">
                      <el-row class="cate2">{{item2.next_name}}</el-row>
                      <el-row>
                        <el-col
                          v-for="(item3,index3) in item2.info"
                          :span="8"
                          :key="index3"
                          class="cate3"
                        >
                          <span @click="getGoodsByCate(item.cid,item3.son_name)">
                            <el-col>{{item3.son_name}}</el-col>
                            <img v-lazy="item3.imgurl" />
                          </span>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-row>
          </el-col>
          <el-col
            :span="6"
            v-for="(item,index) in goodsList"
            :key="index"
            class="goods-item iphone5-24 iphone5-12"
          >
            <GoodsList :goods-info="item" />
          </el-col>
        </el-row>
        <None v-show="showNone" />
        <!-- <el-row class="data-page-box">
          <span class="data-page-botton" @click="dataUpPage">上一页</span>
          <span class="data-page-botton" @click="dataNextPage">下一页</span>
        </el-row>-->
      </el-col>
    </el-row>
  </div>
</template>


<script>
import GoodsList from "@/components/GoodsList.vue";
import None from "@/components/None.vue";
export default {
  asyncData({ store, route }) {
    console.log("asyncData");
    let req = [
      store.dispatch("superClassify2"),
      store.dispatch("goodsCategory", { type: "hcate" }),
    ];
    let params = route.query;
    let keyword = (this.keyword = params.keyword || "");
    let cid = (this.cid = params.cid || 1);
    let sort = (this.sort = params.sort || 0);
    let page = (this.page = params.page || 1);

    if (this.keyword) {
      req = req.concat(
        store.dispatch("hKeywordsGoods", {
          keyword: keyword,
          cid: cid,
          sort: sort,
          page: page,
          offset: 20,
        })
      );
    } else {
      req = req.concat(
        store.dispatch("allGoods", {
          cid: cid,
          page: page,
          offset: 20,
          sort: sort,
        })
      );
    }
    return Promise.all(req);
  },
  computed: {
    superClassify: function () {
      let superClassify2 = this.$store.state.superClassify2.list;
      return superClassify2;
    },
    categoryList: function () {
      let goodsCategory = this.$store.state.goodsCategory.list;
      return goodsCategory;
    },
    goodsList: function () {
      if (this.keyword) {
        let hKeywordsGoods = this.$store.state.hKeywordsGoods.list;
        console.log("hKeywordsGoodsAAAA", this.$store.state.hKeywordsGoods);
        return hKeywordsGoods;
      } else {
        let allGoods = this.$store.state.allGoods.list;
        return allGoods;
      }
    },
    showNone: function () {
      if (this.keyword) {
        const _showNone = this.$store.state.hKeywordsGoods.showNone || false;
          console.log("hKeywordsGoodsBBBBBB", this.$store.state.hKeywordsGoods);
        return _showNone;
      } else {
        const _showNone = this.$store.state.allGoods.showNone || false;
        return _showNone;
      }
    },
    
  },
  components: {
    GoodsList,
    None,
  },
  data() {
    return {
      hostUrl: this.$api.hostUrl,
      tabPosition: "left",
      keyword: "",
      cid: 0,
      sort: 0,
      isLoading: false,
      page: 1,
    };
  },
  mounted: function () {
    console.log("mounted");
    this.$store.dispatch("superClassify2");
    this.$store.dispatch("goodsCategory", { type: "hcate" });

    let _this = this;
    _this.hostUrl = _this.$api.hostUrl;
    let params = this.$route.query;
    this.keyword = params.keyword;
    this.cid = params.cid || 0;
    this.sort = params.sort || 0;

    _this.getSuperClassify();
    _this.getGoodsCategory();
    if (params.keyword) {
      _this.getKeywordGoods();
    } else {
      _this.getAllGoods();
    }
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.offsetHeight -
            document.documentElement.scrollTop -
            window.innerHeight <=
          200;
        // 距离底部200px时加载一次
        let isLoading = _this.isLoading;
        let routeName = _this.$route.name;
        console.log(
          "bottomOfWindow",
          bottomOfWindow,
          "isLoading",
          isLoading,
          "routeName",
          routeName
        );
        if (bottomOfWindow && isLoading == false && routeName == "HAllGoods") {
          _this.isLoading = true;
          if (_this.keyword) {
            _this.getKeywordGoods();
          } else {
            _this.getAllGoods();
          }
        }
      };
    }
  },
  created: function () {},
  methods: {
    getKeywordGoods() {
      let keyword = this.keyword;
      let cid = this.cid;
      let sort = this.sort;
      let page = this.page;
      let _this = this;
      if (page == 1) {
        _this.goodsList = [];
      }
      console.log("keyword", keyword, "cid", cid, "sort", sort, "page", page);
      this.$store.dispatch("hKeywordsGoods", {
        keyword: keyword,
        cid: cid,
        sort: sort,
        page: page,
        offset: 20,
      });
    },
    getAllGoods() {
      let _this = this;
      let cid = this.cid;
      let sort = this.sort;
      let page = this.page;
      if (page == 1) {
        _this.goodsList = [];
      }
      console.log("cid", cid, "page", page, "sort", sort);
      this.$store.dispatch("allGoods", {
        cid: cid,
        page: page,
        offset: 20,
        sort: sort,
      }).then((response) => {
          this.page++;
          this.isLoading = false;
            console.log('response',this.isLoading );
        });
    },
    getSuperClassify() {
      let _this = this;
      _this.$api.hSuperClassify(1, 10, function (res) {
        _this.superClassify = res.data.data.hSuperClassify.super_classify_list;
      });
    },
    getGoodsCategory() {
      let _this = this;
      _this.$api.goodsCategory("hcate", function (res) {
        _this.categoryList = res.data.data.goodsCategory;
      });
    },
    getGoodsListByCid(cid) {
      this.keyword = "";
      this.page = 1;
      this.isLoading = true;
      this.cid = cid;
      this.showNone = false;
      this.$router.push({ name: "HAllGoods", query: { cid: cid } });
    },
    getGoodsByCate(cid, son_name) {
      this.keyword = son_name;
      this.cid = cid;
      this.page = 1;
      this.isLoading = true;
      this.showNone = false;
      this.$router.push({
        name: "HAllGoods",
        query: { cid: cid, keyword: son_name },
      });
      this.getKeywordGoods();
    },
  },
};
</script>