<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <!-- 热销分类商品1 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="16" class="section-title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'Home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'HAllGoods' }">全部商品</el-breadcrumb-item>
                <el-breadcrumb-item>商品详情页</el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
            <el-col
              :span="2"
              :offset="6"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>
        <el-row class="section-content">
          <el-col :offset="7" :span="10" class="goods-item iphone5-24 iphone5-12">
            <el-row class="goods-box">
              <el-col :span="24" class="goods-img h28">
                <el-row class="carousel">
                  <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="(item,index) in goodsDetail.taobao_image" :key="index">
                      <img v-lazy="item" style="with:100%;height:100%;" :key="item">
                    </el-carousel-item>
                  </el-carousel>
                </el-row>
              </el-col>

              <el-col :span="24" class="goods-info">
                <span>
                  <a :href="goodsDetail.taobao_url" target="_blank">
                    <el-row class="goods-title">{{goodsDetail.itemtitle}}</el-row>
                    <el-row class="goods-title color-orange">{{goodsDetail.itemshorttitle}}</el-row>
                    <el-row class="goods-desc">{{goodsDetail.itemdesc}}</el-row>
                  </a>
                  <el-row class="goods-price-box">
                    <el-col class="goods-price" :span="9">
                      <span class="fq-ticket">券</span>
                      <span class="fq-ticket-num">￥{{goodsDetail.couponmoney}}</span>
                    </el-col>
                    <el-col class="goods-price" :span="15">
                      <span
                        class="price-num"
                      >{{goodsDetail.couponreceive2}}/{{goodsDetail.couponnum}}</span>
                      <span class="goods-price-title">&nbsp;已领券/总量</span>
                    </el-col>
                  </el-row>
                  <el-row class="goods-price-box">
                    <el-col class="goods-price" :span="9">
                      <span class="price-title">月销量：</span>
                      <span class="price-num">{{goodsDetail.itemsale}}</span>
                    </el-col>
                    <el-col class="goods-price" :span="15">
                      <span class="goods-price-title">券后价:￥</span>
                      <span class="price-num">{{goodsDetail.itemendprice}}</span>
                    </el-col>
                  </el-row>
                  <el-row class="goods-price-box">
                    <el-col class="goods-price" :span="9">
                      <span class="goods-price-title">券限制:</span>
                      <span class="price-num">{{hCoupon.couponexplain}}</span>
                    </el-col>
                    <el-col class="goods-price com-del" :span="15">
                      <span class="goods-price-title">售价:￥</span>
                      <span class="price-num">{{goodsDetail.itemprice}}</span>
                    </el-col>
                  </el-row>
                  <el-row class="goods-price-box">
                    <el-col class="goods-icon" :span="9">
                      <img
                        v-if="goodsDetail.shoptype == 'B'"
                        :src="hostUrl+'wxAppImage/tianmao.png'"
                      >
                      <img
                        v-if="goodsDetail.shoptype == 'C'"
                        :src="hostUrl+'wxAppImage/taobao.png'"
                      >
                    </el-col>
                    <el-col class="goods-price" :span="15">
                      <span class="goods-price-title">店铺:</span>
                      <span class="price-num">{{goodsDetail.shopname}}</span>
                    </el-col>
                  </el-row>

                  <el-row class="goods-price-box">
                    <el-col class="goods-price" :span="9">
                      <span class="goods-price-title">开始时间：</span>
                      <span class="price-num">{{hCoupon.couponstarttime| parseTime('{y}-{m}-{d}')}}</span>
                    </el-col>
                    <el-col class="goods-price" :span="15">
                      <span class="goods-price-title">结束时间:</span>
                      <span class="price-num">{{hCoupon.couponendtime| parseTime}}</span>
                    </el-col>
                  </el-row>

                  <el-row class="goods-price-box">
                    <el-col class="goods-price" :span="20" :offset="2">
                      <a :href="hCoupon.coupon_click_url" target="_blank">
                        <el-button type="primary" round style="width:100%">点击领取优惠券</el-button>
                      </a>
                    </el-col>
                  </el-row>
                </span>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="10" class="section-title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>猜你喜欢</el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
            <el-col
              :span="2"
              :offset="12"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>
        <el-row class="section-content">
          <el-col
            :span="6"
            v-for="item in goodsList"
            :key="item.index"
            class="goods-item iphone5-24 iphone5-12"
          >
            <GoodsList :goods-info="item"/>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import GoodsList from "@/components/GoodsList.vue";
export default {
  asyncData({ store, route }) {
    let params = route.query;
    this.id = params.id;
    let req = [];
    if (this.id) {
      req = [
        store.dispatch("hGoodsDetail", { id: this.id }),
        store.dispatch("hCoupon", { id: this.id }),
        store.dispatch("hUserLikeGoods", { id: this.id })
      ];
    }
    return Promise.all(req);
  },
  computed: {
    hCoupon:function() {
      let hCoupon = this.$store.state.hCoupon.data;
    console.log("HGoodsDetai:hCoupon",hCoupon)
      return hCoupon;
    },
    goodsDetail:function() {
      let _this = this;
      let goodsDetail = this.$store.state.hGoodsDetail.data;
      console.log('AAAgoodsDetail',goodsDetail);
      if (goodsDetail.itemid) {
        let itempic = goodsDetail.itempic;
        let a = [];
        if (typeof(goodsDetail.taobao_image) == 'string') {
          a = goodsDetail.taobao_image.split(",");
        }
        a.unshift(itempic);
        goodsDetail.taobao_image = a;
      } else {
        // _this.alertMsg();
      }
      return goodsDetail;
    },
    goodsList() {
      let hUserLikeGoods = this.$store.state.hUserLikeGoods.list;
      return hUserLikeGoods;
    }
  },
  components: {
    GoodsList
  },
  data() {
    return {
      hostUrl: this.$api.hostUrl
    };
  },
  mounted: function() {
    let _this = this;
    let params = this.$route.query;
    this.id = params.id;
    console.log(' params.id', params.id);
    // if (params.id) {
      _this.getGoodsAndCoupon(params.id);
    // } else {
    //   _this.alertMsg();
    // }
  },
  methods: {
    getGoodsAndCoupon(id) {
      this.$store.dispatch("hGoodsDetail", { id: this.id });
      this.$store.dispatch("hCoupon", { id: this.id });
      this.$store.dispatch("hUserLikeGoods", { id: this.id });
    },
    alertMsg() {
      let _this = this;
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        message: h("p", null, [h("span", null, "没有查询到该商品信息！ ")]),
        showCancelButton: true,
        cancelButtonText: "尝试领取",
        confirmButtonText: "返回首页",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            _this.$router.push({ name: "Home" });
            done();
          } else {
            done();
          }
        }
      })
        .then(action => {
          this.$message({
            type: "info",
            message: "action: " + action
          });
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  }
};
</script>