<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <!-- 商品列表 -->
        <el-row class="section">
          <el-row class="section-title-box">
            <el-row class="section-title-item">
              <el-col :span="10" class="section-title">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                  <el-breadcrumb-item>抢购</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col
                :span="2"
                :offset="12"
                class="section-title-icon el-icon-d-arrow-right com-right"
              ></el-col>
            </el-row>
          </el-row>
          <el-row class="section-content">
            <el-col :span="20" :offset="2">
              <el-tabs
                :tab-position="tabPosition"
                v-model="hourType"
                @tab-click="getQingByTime"
                class="qiang-menu"
              >
                <el-tab-pane
                  v-for="(item,index) in qingTime"
                  :key="index"
                  :name="item.type"
                  :label="item.name"
                ></el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>

          <el-row>
            <el-col
              :span="18"
              :offset="3"
              v-for="(item,index) in goodsList"
              :key="index"
              class="goods-item iphone5-24 iphone5-12"
            >
              <GoodsListY class="qiang-info" :active="started" :goods-info="item"/>
            </el-col>
          </el-row>
          <None v-show="showNone"/>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import GoodsListY from "@/components/GoodsListY.vue";
import None from "@/components/None.vue";
export default {
  asyncData({ store, route }) {
    let params = route.query;
    this.hourType = params.hourType;
    this.page = params.page;

    return Promise.all([
      store.dispatch("hFastbuyGoods", { type:  this.hourType, page:  this.page, offset: 12 })
    ]);
  },
  computed: {
    goodsList() {
      let hFastbuyGoods = this.$store.state.hFastbuyGoods.list;
      if(!hFastbuyGoods){
          this.showNone = true;
      }
      return hFastbuyGoods;
    },
    page() {
      let page = this.$store.state.hFastbuyGoods.page || 1;
   
      return page;
    }
  },
  watch: {
    page(val) {
      this.isLoading = false;
    }
  },
  components: {
    GoodsListY,
    None
  },
  data() {
    return {
      tabPosition: "top",
      qingTime: [
        { type: "1", name: "昨天00:00" },
        { type: "2", name: "昨天10:00" },
        { type: "3", name: "昨天12:00" },
        { type: "4", name: "昨天15:00" },
        { type: "5", name: "昨天20:00" },
        { type: "6", name: "今天0:00" },
        { type: "7", name: "今天10:00" },
        { type: "8", name: "今天12:00" },
        { type: "9", name: "今天15:00" },
        { type: "10", name: "今天20:00" },
        { type: "11", name: "明天0:00" },
        { type: "12", name: "明天10:00" },
        { type: "13", name: "明天12:00" },
        { type: "14", name: "明天15:00" },
        { type: "15", name: "明天20:00" }
      ],
      hourType: "1",
      showNone: "",
      started: true,
      hostUrl: this.$api.hostUrl
    };
  },
  created: function() {},
  mounted: function() {
    let params = this.$route.query;
    this.hourType = params.hourType || 1;
    this.page = params.page || 1;
    this.getQiangGoods();
  },
  methods: {
    getQiangGoods() {
      if (this.hourType > 10) {
        this.started = false;
      }
      this.$store.dispatch("hFastbuyGoods", { type:  this.hourType, page: this.page, offset: 12 })
    },
    getQingByTime() {
      let hourType = this.hourType;
      let page = this.page;
      this.$router.push({
        name: "HQiang",
        query: { page: page, hourType: hourType }
      });
      this.getQiangGoods();
    },
    goDetail(id) {
      this.$router.push({ name: "HGoodsDetail", query: { id: id } });
    }
  }
};
</script>