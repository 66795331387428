<template>

      <el-row class="goods-box height450 com-hover">
          <span @click="goDetail(goodsInfo.itemid)">
          <el-col :span="24" class="goods-img h28">
            <img v-lazy="goodsInfo.itempic" :key="goodsInfo.itempic">
          </el-col>
          </span>
        <!-- <el-col :span="24" class="goods-info-top">↑ 点击上面去淘宝 ↓ 点击下面来领券</el-col> -->
        <el-col :span="24" class="goods-info">
          <span @click="goDetail(goodsInfo.itemid)">
            <el-row class="goods-title">{{goodsInfo.itemshorttitle2}}</el-row>
             <el-row class="goods-title color-orange">{{goodsInfo.itemshorttitle}}</el-row>
            <el-row class="goods-price-box">
              <el-col class="goods-price" :span="9">
                <span class="fq-ticket">券</span><span class="fq-ticket-num">￥{{goodsInfo.couponmoney}}</span>
              </el-col>
              <el-col class="goods-price" :span="15" v-if="goodsInfo.couponreceive2">
                <span class="price-num">{{goodsInfo.couponreceive2}}/{{goodsInfo.couponnum}}</span>
                <span class="goods-price-title">&nbsp;已领券/总量</span>
              </el-col>
            </el-row>
            <el-row class="goods-price-box">
              <el-col class="goods-price" :span="9"  v-if="goodsInfo.itemsale">
                <span class="price-title">月销量:</span>
                <span class="price-num">{{goodsInfo.itemsale}}</span>
              </el-col>
              <el-col class="goods-price" :span="15"  v-if="goodsInfo.itemendprice">
                <span class="goods-price-title">券后价:￥</span>
                <span class="price-num">{{goodsInfo.itemendprice}}</span>
              </el-col>
            </el-row>
            <el-row class="goods-price-box"  v-if="goodsInfo.itemprice">
              <el-col class="goods-price" :span="9">&nbsp;</el-col>
              <el-col class="goods-price com-del" :span="15">
                <span class="goods-price-title">售价:￥</span>
                <span class="price-num">{{goodsInfo.itemprice}}</span>
              </el-col>
            </el-row>
            <el-row class="goods-price-box"  v-if="goodsInfo.shoptype">
              <el-col class="goods-icon" :span="6">
                <img v-if="goodsInfo.shoptype == 'B'" :src="hostUrl+'wxAppImage/tianmao.png'">
                <img v-if="goodsInfo.shoptype == 'C'" :src="hostUrl+'wxAppImage/taobao.png'">
              </el-col>
              <el-col class="goods-price" :span="18"  v-if="goodsInfo.shopname">
                <span class="goods-price-title">店铺:</span>
                <span class="price-num">{{goodsInfo.shopname}}</span>
              </el-col>
            </el-row>
          </span>
        </el-col>
      </el-row>


</template>


<script>
export default {
  data() {
    let hostUrl = this.$api.hostUrl;
    return {
      hostUrl: hostUrl,
    };
  },
  props: ["goodsInfo"],
  created: function() {
 
  },
  methods: {
    goDetail(id) {
      this.$router.push({ name: "HGoodsDetail", query: { id: id } });
    }
  }
};
</script>