<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <el-row>
          <el-col class="com-pd10">
            <el-row>
              <el-row class="brand-menu">
                <el-col class="brand-wrap">
                  <el-row>
                    <el-col class="brand-uppage" :span="1">
                      <span class="page-button" @click="brandUpPage">上一页</span>
                    </el-col>
                    <el-col class="brand-logo-list">
                      <el-col
                        v-for="(item,index) in brandList"
                        :key="index"
                        :span="2"
                        class="brand-logo-img"
                      >
                        <span @click="goBrandDetail(item.id)">
                          <img v-lazy="item.brand_logo" :key="item.brand_logo">
                        </span>
                      </el-col>
                    </el-col>
                    <el-col class="brand-nextPage" :span="1">
                      <span class="page-button" @click="brandNextPage">下一页</span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-row>
          </el-col>
        </el-row>

        <!-- 商品列表 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="10" class="section-title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/hBrandGoods' }">超值大牌</el-breadcrumb-item>
                <el-breadcrumb-item>详情页</el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
            <el-col
              :span="2"
              :offset="12"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>

        <el-row class="goods-item">
          <el-col :span="16" :offset="4" class="border-shadow qiang-info">
            <el-col :span="8" class="goods-img">
              <img v-lazy="brandDetail.brand_logo+'&_200x200.jpg'" :key="brandDetail.brand_logo">
            </el-col>
            <el-col :span="16" class="goods-info">
              <el-row class="goods-title">{{brandDetail.tb_brand_name}}</el-row>
              <el-row class="goods-desc">{{brandDetail.introduce}}</el-row>
            </el-col>
          </el-col>
        </el-row>

        <el-row class="data-list">
          <el-col
            :span="6"
            v-for="(item,index) in brandDetail.items"
            :key="index"
            class="goods-item iphone5-24 iphone5-12"
          >
            <GoodsList :goods-info="item"/>
          </el-col>
        </el-row>

        <!-- <el-row class="data-page-box">
            <span class="data-page-botton" @click="dataUpPage">上一页</span>
            <span class="data-page-botton" @click="dataNextPage">下一页</span>
        </el-row>-->
      </el-col>
    </el-row>
  </div>
</template>


<script>
import GoodsList from "@/components/GoodsList.vue";
export default {
  asyncData({ store, route }) {
    let params = route.query;
    this.brandGoodsPage = params.page || 1;
    this.brandId = params.id || "";

    let req = [
      store.dispatch("hBrandList", { page: 1, offset: 18 }),
      store.dispatch("hBrandDetail", {
        brandId: this.brandId,
        page: this.brandGoodsPage,
        offset: 10
      })
    ];
    return Promise.all(req);
  },
  computed: {
    brandList() {
      let brandList = this.$store.state.hBrandList.list;
      return brandList;
    },
    brandDetail() {
      let hBrandDetail = this.$store.state.hBrandDetail.data;
      console.log("hBrandDetail",hBrandDetail)
      return hBrandDetail;
    },
    brandPage() {
      let page = this.$store.state.hBrandList.page || 1;
      return page;
    }
  },
  components: {
    GoodsList
  },
  data() {
    return {
      hostUrl: this.$api.hostUrl,
      brandId: ""
    };
  },
  mounted: function() {
    let params = this.$route.query;
    if (params.page) {
      this.brandGoodsPage = params.page;
    }
    if (params.id) {
      this.brandId = params.id;
    }
    let brandPageNum = this.brandPage = 1;
    let brandGoodsPage = this.brandGoodsPage;
    this.getBrandList(brandPageNum);
    this.getBrandDetail();
  },
  created: function() {},
  methods: {
    getBrandList(page) {
      this.$store.dispatch("hBrandList", { page: page, offset: 18 });
    },
    brandUpPage() {
      let upPage = this.brandPage - 2;
      console.log("upPage", upPage);
      if (upPage > 0) {
        this.getBrandList(upPage);
      }
    },
    brandNextPage() {
      let nextPage = this.brandPage;
      console.log("nextPage", nextPage);
      if (nextPage < 4) {
        this.getBrandList(nextPage);
      }
    },
    getBrandDetail() {
      this.brandGoodsPage = 1;
      this.$store.dispatch("hBrandDetail", {
        brandId: this.brandId,
        page: this.brandGoodsPage,
        offset: 10
      });
    },
    goBrandDetail(id) {
      this.brandPage = 1;
      this.$router.push({ name: "HBrandDetail", query: { id: id } });
    }
  }
};
</script>