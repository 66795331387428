import axios from 'axios';
import { Loading } from 'element-ui';

var api = api || {};

var header = function () {
    return '';
    // let  auth_token = localStorage.getItem('auth_token');
    // return {
    //     "Content-Type": "application/json",
    //     "Authorization": `Bearer ${auth_token}`,
    // };
}
api.hostUrl = 'https://ytxd.52tuyou.com/';
api.baseUrl = 'https://ytxd.52tuyou.com/graphql/';

// api.hostUrl = 'http://localhost:9090';
// api.baseUrl = 'http://localhost:9090/graphql';

var ajax = function (req, callback) {
    if (typeof window !== "undefined") {
        var loading = Loading.service({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
        });
    }
    return axios(req).then(function (response) {
        if (typeof window !== "undefined") {
            setTimeout(function () { loading.close(); }, 500);
        }
        callback && callback(response);
        return response;
    });
};

api.login = (callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        data: {
            query: `mutation{login(email:"ytxdpc",password:"youtaoxiaodain"){auth_token}}`
        }
    }, function (response) {
        // let auth_token = response.data.data.login.auth_token;
        // localStorage.setItem('auth_token', auth_token);
        callback && callback(response);
    });
};

// headers: {'X-Requested-With': 'XMLHttpRequest'},

api.NoticeList = (type, page = 1, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{noticeList(page:"${page}",type:"${type}"){notice_id,notice_title,link_wxappid,notice_link,link_type,notice_img}}`
        }
    }, callback);
};

api.goodsCategory = (cat_type, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{goodsCategory(cat_type:"${cat_type}"){ cat_id,cat_code,cat_name,cat_desc,img}}`
        }
    }, callback);
};

api.usersSearchLog = (callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{usersSearchLog{search_string}}`
        }
    }, callback);
};

api.taobaoGetCoupon = (goods_id, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{taobaoGetCoupon(goodsId:"${goods_id}"){title,num_iid,reserve_price,zk_final_price,provcity,volume,nick,small_images,coupon{couponLinkTaoToken}}}`
        }
    }, callback);
};


api.taobaoGoods = (reqStr, page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{taobaoGoods(reqStr:"${reqStr}",page:"${page}",offset:"${offset}"){title,shorttitle,pictUrl,couponAmount,couponInfo,couponLeftCount,tkRate,tkCommFee,shopTitle,auctionId,biz30day,zkPrice,isTbPt,taobao_url}}`
        }
    }, callback);
};

api.hAllGoods = (cid = 0, page = 1, offset = 10, sort = 0, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hAllGoods(cid:"${cid}",page:"${page}",offset:"${offset}",sort:"${sort}"){min_id,msg,code,all_goods_list{product_id,itemid,taobao_url,itemprice,itemsale,itempic,itemendprice,shoptype,couponmoney,couponreceive2,couponnum,couponexplain,shopname,itemtitle,itemshorttitle,itemshorttitle2}}}`
        }
    }, callback);
};


api.hBrandList = (page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hBrandList(page:"${page}",offset:"${offset}"){
                min_id,msg,code,brand_list{id,tb_brand_name,fq_brand_name,brand_logo,introduce}
            }}`
        }
    }, callback);
};

api.hBrandGoods = (id = 0, page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hBrandGoods(id:"${id}",page:"${page}",offset:"${offset}"){
                msg,code,brand_goods{id,tb_brand_name,fq_brand_name,brand_logo,introduce,items{itemid,itemprice,itemsale,itempic,itemendprice,shoptype,couponmoney,couponexplain,shopname,itemtitle,itemshorttitle,itemshorttitle2,taobao_url}}
            }}`
        }
    }, callback);
};


api.hBrandsAndGoods = (cid = 0, page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hBrandsAndGoods(cid:"${cid}",page:"${page}",offset:"${offset}"){
                min_id,msg,code,brands_goods_list{id,tb_brand_name,fq_brand_name,brand_logo,introduce,item{itemid,itemprice,itemsale,itempic,itemendprice,shoptype,couponmoney,couponexplain,shopname,itemtitle,itemshorttitle,itemshorttitle2,taobao_url}}
            }}`
        }
    }, callback);
};



api.hHotGoods = (cid = 0, sale_type = 1, page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hHotGoods(sale_type:"${sale_type}",cid:"${cid}",page:"${page}",offset:"${offset}"){min_id,msg,code,hot_goods_list{product_id,itemid,itemprice,itemsale,itempic,itemendprice,shoptype,couponmoney,couponreceive2,couponnum,couponexplain,shopname,itemtitle,itemshorttitle,itemshorttitle2,taobao_url}}}`
        }
    }, callback);
};


api.hTalentInfo = (cid = 0, page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hTalentInfo(cid:"${cid}",page:"${page}",offset:"${offset}"){min_id,msg,code,talent_info_list{topdata{id,name,shorttitle,image,label,tk_item_id,readtimes,talent_name,article_banner},newdata{id,name,shorttitle,image,label,tk_item_id,readtimes,talent_name,head_img,article_banner},clickdata{id,name,shorttitle,image,label,tk_item_id,readtimes,talent_name,head_img,article_banner}}}}`
        }
    }, callback);
};

api.hTalentDetail = (id = '', callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hTalentDetail(id:"${id}"){min_id,msg,code,talent_info{id,name,compose_image,article_banner,talent_name,head_img,readtimes,article,items{itemid,itemshorttitle,itemshorttitle2,itemsale,itemtitle,itemendprice,couponmoney,itempic,taobao_url}}}}`
        }
    }, callback);
};


api.hWxGoods = (page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hWxGoods(page:"${page}",offset:"${offset}"){min_id,msg,code,wx_goods_list{edit_id}}}`
        }
    }, callback);
};

api.hFastbuyGoods = (hour_type = 0, page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hFastbuyGoods(hour_type:"${hour_type}",page:"${page}",offset:"${offset}"){min_id,msg,code,fastbuy_goods_list{itemid,itemtitle,couponreceive,couponnum,itemshorttitle,itemshorttitle2,itemdesc,itemprice,itemsale,todaysale,itempic,itemendprice,couponmoney,taobao_url}}}`
        }
    }, callback);
};


api.hSpecialGoods = (page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hSpecialGoods(page:"${page}",offset:"${offset}"){min_id,msg,code,special_goods_list{itemid,itemtitle,itemshorttitle}}}`
        }
    }, callback);
};

api.hKeywords = (page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hKeywords(page:"${page}",offset:"${offset}"){keyword}}`
        }
    }, callback);
};

api.hKeywordsGoods = (keyword = '', cid = 0, sort = 0, page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hKeywordsGoods(keyword:"${keyword}",cid:"${cid}",sort:"${sort}",page:"${page}",offset:"${offset}"){min_id,msg,code,keywords_goods_list{itemid,taobao_url,itemtitle,itemshorttitle,itemshorttitle2,couponmoney,couponnum,couponreceive,couponreceive2,itempic,itemendprice,shopname,shoptype,itemprice,itemsale,todaysale,couponreceive,couponsurplus,couponexplain}}}`
        }
    }, callback);
};



api.hSuperClassify = (page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hSuperClassify(page:"${page}",offset:"${offset}"){min_id,msg,code,super_classify_list{cid,main_name,data{next_name,info{son_name,imgurl}}}}}`
        }
    }, callback);
};



api.hSubject = (page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hSubject(page:"${page}",offset:"${offset}"){min_id,msg,code,subject_list{name}}}`
        }
    }, callback);
};


api.hSubjectGoods = (subject_id = '', page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hSubjectGoods(subject_id:"${subject_id}",page:"${page}",offset:"${offset}"){min_id,msg,code,subject_goods_list{itemid,itemtitle,itemshorttitle}}}`
        }
    }, callback);
};


api.hGoodsDetail = (itemid = null, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hGoodsDetail(itemid:"${itemid}"){itemid,itemtitle,itemshorttitle,itemdesc,couponmoney,couponnum,couponreceive,couponreceive2,itempic,itemendprice,shopname,shoptype,itemprice,itemsale,todaysale,couponreceive,couponsurplus,couponexplain,taobao_image,taobao_url}}`
        }
    }, callback);
};





api.hUserLikeGoods = (itemid = null, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hUserLikeGoods(itemid:"${itemid}"){min_id,msg,code,user_like_goods_list{itemid,itemtitle,itemshorttitle,couponmoney,couponnum,couponreceive,couponreceive2,itempic,itemendprice,shopname,shoptype,itemprice,itemsale,todaysale,couponreceive,couponsurplus,couponexplain,taobao_url}}}`
        }
    }, callback);
};




api.hGoodsArtical = (page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hGoodsArtical(page:"${page}",offset:"${offset}"){min_id,msg,code,goods_artical_list{itemid,itemshorttitle}}}`
        }
    }, callback);
};




api.hTodayGoods = (page = 1, offset = 10, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hTodayGoods(page:"${page}",offset:"${offset}"){msg,code,today_goods_list{itemid,itemtitle,itemshorttitle,itemshorttitle2,couponmoney,couponnum,couponreceive,couponreceive2,itempic,itemendprice,shopname,shoptype,itemprice,itemsale,todaysale,couponreceive,couponsurplus,couponexplain,taobao_url}}}`
        }
    }, callback);
};


api.hSearch = (keyword = '', page = 1, offset = 10, sort = 0, tb_p = 1, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hSearch(keyword:"${keyword}",page:"${page}",offset:"${offset}",sort:"${sort}",tb_p:"${tb_p}"){min_id,msg,code,all_goods_list{itemid,itemtitle,itemshorttitle,couponmoney,itempic,itemendprice,shopname,shoptype,itemprice,itemsale}}}`
        }
    }, callback);
};

api.hCoupon = (itemid = 1, callback = null) => {
    return ajax({
        method: 'post',
        url: api.baseUrl,
        headers: header(),
        data: {
            query: `mutation{hCoupon(itemid:"${itemid}"){coupon_click_url,couponstarttime,couponendtime,couponexplain}}`
        }
    }, callback);
};

export default api;