<template>
  <el-row class="com-hover">
  <span v-if="active" @click="goDetail(goodsInfo.itemid)">
      <el-col :span="8" class="goods-img iphone5-24">
        <img v-lazy="goodsInfo.itempic" :key="goodsInfo.itempic">
      </el-col>
    </span>
    <el-col :span="16" class="goods-info iphone5-24">
      <span v-if="active" @click="goDetail(goodsInfo.itemid)">
        <el-row class="goods-title">{{goodsInfo.itemtitle}}</el-row>
         <el-row class="goods-title color-orange">{{goodsInfo.itemshorttitle}}</el-row>
        <el-row class="goods-desc">{{goodsInfo.itemdesc}}</el-row>
        <el-row class="goods-price-box">
          <el-col :span="12">
            <el-col class="goods-price">
              <span class="goods-price-title">券后价格：</span>
              <span class="price-num">{{goodsInfo.itemendprice}}</span>
            </el-col>
            <el-col class="goods-price com-del">
              <span class="goods-price-title">售价格：</span>
              <span class="price-num">{{goodsInfo.itemprice}}</span>
            </el-col>

            <el-col class="goods-price">
              <span class="goods-price-title">月销量：</span>
              <span class="price-num">{{goodsInfo.itemsale}}</span>
            </el-col>
            <el-col class="goods-price">
              <span class="goods-price-title">今日销量：</span>
              <span class="price-num">{{goodsInfo.todaysale}}</span>
            </el-col>
          </el-col>
          <el-col :span="12">
            <el-col class="goods-price">
              <span class="fq-ticket">券</span>
              <span class="fq-ticket-num">{{goodsInfo.couponmoney}}</span>
            </el-col>
            <el-col class="goods-price">
              <span class="price-num">{{goodsInfo.couponreceive}}/{{goodsInfo.couponnum}}</span>
              <span class="goods-price-title">&nbsp;已领券/总量</span>
            </el-col>
          </el-col>
        </el-row>
      </span>
      <span v-else>
        <el-row class="goods-title">{{goodsInfo.itemtitle}}</el-row>
        <el-row class="goods-title color-orange">{{goodsInfo.itemshorttitle}}</el-row>
        <el-row class="goods-desc">{{goodsInfo.itemdesc}}</el-row>
        <el-row class="goods-price-box">
          <el-col :span="12">
            <el-col class="goods-price">
              <span class="goods-price-title">券后价格：</span>
              <span class="price-num">{{goodsInfo.itemendprice}}</span>
            </el-col>
            <el-col class="goods-price com-del">
              <span class="goods-price-title">售价格：</span>
              <span class="price-num">{{goodsInfo.itemprice}}</span>
            </el-col>

            <el-col class="goods-price">
              <span class="goods-price-title">月销量：</span>
              <span class="price-num">{{goodsInfo.itemsale}}</span>
            </el-col>
            <el-col class="goods-price">
              <span class="goods-price-title">今日销量：</span>
              <span class="price-num">{{goodsInfo.todaysale}}</span>
            </el-col>
          </el-col>
          <el-col :span="12">
            <el-col class="goods-price">
              <span class="fq-ticket">券</span>
              <span class="fq-ticket-num">{{goodsInfo.couponmoney}}</span>
            </el-col>
            <el-col class="goods-price">
              <span class="price-num">{{goodsInfo.couponreceive}}/{{goodsInfo.couponnum}}</span>
              <span class="goods-price-title">&nbsp;已领券/总量</span>
            </el-col>
          </el-col>
        </el-row>
          <el-row class="com-center sys-info">
          <el-col >
           活动还未开始，敬请期待！
          </el-col>
        </el-row>
      </span>
    </el-col>
  </el-row>
</template>


<script>
export default {
  data() {
    let hostUrl = this.$api.hostUrl;
    return {
      hostUrl: hostUrl
    };
  },
  props: ["goodsInfo", "active"],
  created: function() {},
  methods: {
    goDetail(id) {
      this.$router.push({ name: "HGoodsDetail", query: { id: id } });
    }
  }
};
</script>