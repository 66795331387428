<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <!-- <el-row class="banner">
          <el-col class="tag-box com-pd10">
            <el-row>
              <el-row>
                <el-tag class="tag-title">热门类目</el-tag>
                <el-tag v-for="item in categoryList" :key="item.index">
                  <span
                    @click="getTalentByCat(item.cat_code)"
                    :class="cid==item.cat_code? 'red' :''"
                  >{{item.cat_name}}</span>
                </el-tag>
              </el-row>
            </el-row>
          </el-col>
        </el-row>-->

        <!-- 达人文集 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="10" class="section-title">达人文集:</el-col>
            <el-col
              :span="2"
              :offset="12"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>

        <el-row class="section-content">
          <el-col
            :span="8"
            v-for="(item,index) in talentInfoList"
            :key="index"
            class="goods-item iphone5-24 iphone5-12"
          >
            <span @click="goDetail(item.id)">
              <el-row class="goods-box height220 com-hover">
                <el-col :span="24" class="goods-img">
                  <img v-lazy="item.article_banner" :key="item.article_banner">
                </el-col>
                <el-col :span="24" class="goods-info">
                  <el-row class="goods-title">{{item.name}}</el-row>
                  <el-row class="goods-title">{{item.shorttitle}}</el-row>
                  <el-row class="goods-price-box">
                    <el-col class="goods-price" :span="12">
                      <span class="goods-price-title">达人名字：</span>
                      <span class="price-num">{{item.talent_name}}</span>
                    </el-col>

                    <el-col class="goods-price" :span="12">
                      <span class="goods-price-title">阅读数：</span>
                      <span class="price-num">{{item.readtimes}}</span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
  asyncData({ store, route }) {
    let params = route.query;
    this.page = params.page || 1;
    this.cid = params.cid || 0;

    return Promise.all([
      store.dispatch("hTalentInfo", { cid: this.cid, page: this.page , offset: 10 })
    ]);
  },
  computed: {
    categoryList() {
      let categoryList = this.$store.state.categoryList;
      return categoryList;
    },
    talentInfoList() {
      let hTalentInfo = this.$store.state.hTalentInfo.list;
      let talentInfoList = [];
      if (hTalentInfo) {
        let topInfoList = hTalentInfo.topdata;
        let newInfoList = hTalentInfo.newdata;
        let clickInfoList = hTalentInfo.clickdata;
         talentInfoList = talentInfoList
        .concat(topInfoList)
        .concat(newInfoList)
        .concat(clickInfoList);
      }
      return talentInfoList;
    },
    page() {
      let page = this.$store.state.hTalentInfo.page || 1;
      return page;
    }
  },
  watch: {
    page(val) {
      this.isLoading = false;
    }
  },
  data() {
    return {
      cid: 0,
      hostUrl: this.$api.hostUrl
    };
  },

  mounted: function() {
    let params = this.$route.query;
    this.cid = params.cid || 0;
    this.getTalentList();
    this.getGoodsCategory();
  },
  methods: {
    getTalentList() {
      let _this = this;
      let cid = this.cid;
      let page = this.page;
      this.$store.dispatch("hTalentInfo", { cid: cid, page: page, offset: 10 });
    },

    getGoodsCategory() {
      this.$store.dispatch("goodsCategory", { type: "hTalent" });
    },
    getTalentByCat(cid) {
      this.cid = cid;
      this.$router.push({ name: "HTalent", query: { cid: cid } });
      this.getTalentList();
    },
    goDetail(id) {
      this.$router.push({ name: "HTalentDetail", query: { id: id } });
    }
  }
};
</script>