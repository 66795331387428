<template>
  <el-row>
    <el-col class="com-center foot">
      <el-row>
        <strong>特此声明：欢迎大家来访，如果有疑问请联系管理员，可加入上面的微信群，一起嗨淘！！</strong>
      </el-row>
      <el-row>

        <p><a href="mailto:304593191@qq.com">联系方式：Email:304593191@qq.com</a></p>
      </el-row>

      <el-row>
        <el-col :span="12">
          <strong>&copy; 2019-2024 52tuyou.com 版权所有</strong>
        </el-col>
        <el-col :span="12">
          <b>辽ICP备15003988号-2.</b>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {};
</script>