<template>
  <div id="app">
    <div id="nav">
      <NavMenu/>
    </div>
    <router-view :key="JSON.stringify($route.query)"/>
    <Foot/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavMenu from "@/components/NavMenu.vue";
import Foot from "@/components/Foot.vue";
export default {
  name: "home",
  components: {
    NavMenu,
    Foot
  },
  data() {
    return {
    };
  },
  beforeCreate: function() {
    // let _this = this;
    // this.$login(function(resUser) {
    //   _this.$api.hKeywords(1, 10, function(res) {
    //     _this.keywordsList = res.data.data.hKeywords;
    //   });
    // });
  }
};
</script>

<style lang="less">
// #app {
//   font-family: 'Avenir', Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
#nav {
  font-size: 10px !important;
}
</style>
