<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <!-- 达人文集 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="10" class="section-title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'Home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'HTalent' }">达人文集</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
            <el-col
              :span="2"
              :offset="12"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>

        <el-row class="section-content">
          <el-col :offset="1" :span="16" class="goods-item">
            <el-row class="goods-box">
              <el-col :span="24" class="goods-img">
                <el-row class="com-center com-fz10">
                  <el-col class="talent-name">{{talentDetail.name}}</el-col>
                  <el-col>
                    <span class="info-label">达人名:</span>
                    <span class="info-text">{{talentDetail.talent_name}}</span>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <span class="info-label">阅读数:</span>
                    <span class="info-text">{{talentDetail.readtimes}}</span>
                  </el-col>
                </el-row>
              </el-col>

              <el-col :span="24" class="talent-info">
                <el-row class="carousel">
                  <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item>
                      <img
                        v-lazy="talentDetail.article_banner"
                        style="with:100%;height:100%;"
                        :key="talentDetail.article_banner"
                      >
                    </el-carousel-item>
                  </el-carousel>
                </el-row>

                <el-row class="talent-desc com-center" v-html="talentDetail.article"></el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" v-for="item in talentDetail.items" :key="item.index" class="goods-item">
            <GoodsList :goods-info="item"/>
          </el-col>
        </el-row>
        <!-- 文章商品 -->
      </el-col>
    </el-row>
  </div>
</template>


<script>
import "../../static/css/talentDetail.css";
import GoodsList from "@/components/GoodsList.vue";
export default {
  asyncData({ store, route }) {
    let params = route.query;
    this.id = params.id || "";

    return Promise.all([store.dispatch("hTalentDetail", { id: this.id })]);
  },
  computed: {
    talentDetail() {
      let hTalentDetail = this.$store.state.hTalentDetail.list;
      return hTalentDetail;
    }
  },
  components: {
    GoodsList
  },
  data() {
    return {
      hostUrl: this.$api.hostUrl,
      id: 0
    };
  },

  mounted: function() {
    let params = this.$route.query;
    this.id = params.id || "";
    if (this.id) {
      this.getTalentDetail();
    }
  },
  methods: {
    getTalentDetail() {
      this.$store.dispatch("hTalentDetail", { id: this.id });
    }
  }
};
</script>