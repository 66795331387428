<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1" class="iphone5-24">
        <el-row class="banner">
          <el-col :xs="24" :sm="12" :md="7" :lg="7"  class="cate-list-box iphone5-12">
            <el-row>
              <!-- <TagsPage :home-menu-h="200"></TagsPage> -->

              <el-tabs :tab-position="tabPosition" class="home-menu-h">
                <el-tab-pane
                  class="cat-list-scroll home-menu-h"
                  v-for="(item,index) in superClassify"
                  :key="index"
                  :label="item.main_name"
                >
                  <el-row>
                    <el-col v-for="(item2,index2) in item.data" :key="index2" class="tag-box">
                      <el-row class="cate2">{{item2.next_name}}</el-row>
                      <el-row>
                        <el-col
                          v-for="(item3,index3) in item2.info"
                          :span="8"
                          :key="index3"
                          class="cate3"
                        >
                          <span @click="getGoodsByCate(item.cid,item3.son_name)">
                            <el-col>{{item3.son_name}}</el-col>
                            <img v-lazy="item3.imgurl">
                          </span>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-row>
          </el-col>
          <el-col :span="13" class="iphone5-24 iphone5-12">
            <el-row class="carousel">
              <el-carousel :interval="5000" arrow="always">
                <el-carousel-item v-for="item in swiperList" :key="item.notice_link">
                  <a :href="item.notice_link" v-if="item.link_type==2">
                    <img :src="item.notice_img" style="with:100%;height:100%;">
                  </a>
                  <img v-else :src="item.notice_img" style="with:100%;height:100%;">
                </el-carousel-item>
              </el-carousel>
            </el-row>
            <el-row class="com-center sys-info talk-show">
              <el-col :span="24">出现空白页面，请尝试刷新！</el-col>
              <el-col :span="24">喜欢就分享下！</el-col>
            </el-row>
          </el-col>
          <el-col :span="4" class="qrCode">
            <el-col :span="24">
              <span>小程序二维码</span>
              <img :src="hostUrl+'wxAppImage/appQrCode.jpg'">
            </el-col>
            <el-col :span="24">
              <span>来群我们聊聊</span>
              <img :src="hostUrl+'wxAppImage/qun001.jpg'">
            </el-col>
          </el-col>
        </el-row>
        <el-row class="cate-list">
          <el-col>
            <!-- <el-row>
              <el-col :span="4" v-for="item in categoryList" :key="item.cat_id" class="cate-box">
                <el-row class="cate-name">{{item.cat_name}}</el-row>
                <el-row class="cate-desc">{{item.cat_desc}}</el-row>
                <el-row>
                  <img :src="item.img">
                </el-row>
              </el-col>
            </el-row>-->
          </el-col>
        </el-row>

        <!-- 推荐商品 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="10" class="section-title">今日推荐:</el-col>
            <el-col
              :span="2"
              :offset="12"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>
        <el-row class="section-content">
          <el-col
            :span="6"
            v-for="(item,index) in todayTjList"
            :key="index"
            class="goods-item iphone5-24 iphone5-12"
          >
            <GoodsList :goods-info="item"/>
          </el-col>
        </el-row>

        <!-- 达人文集 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="10" class="section-title">达人文集:</el-col>
            <el-col
              :span="2"
              :offset="12"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>

        <el-row class="section-content">
          <el-col
            :span="8"
            v-for="item in newInfoList"
            :key="item.index"
            class="goods-item iphone5-24 iphone5-12"
          >
           <span @click="goDetail(item.id)">
            <el-row class="goods-box height220 com-hover">
              <el-col :span="24" class="goods-img">
                <img v-lazy="item.article_banner">
              </el-col>
              <el-col :span="24" class="goods-info">
                <el-row class="goods-title">{{item.name}}</el-row>
                <el-row class="goods-title">{{item.shorttitle}}</el-row>
                <el-row class="goods-price-box">
                  <el-col class="goods-price" :span="12">
                    <span class="goods-price-title">达人名字：</span>
                    <span class="price-num">{{item.talent_name}}</span>
                  </el-col>

                  <el-col class="goods-price" :span="12">
                    <span class="goods-price-title">阅读数：</span>
                    <span class="price-num">{{item.readtimes}}</span>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
             </span>
          </el-col>
        </el-row>

        <!-- 热销分类商品1 -->

        <el-row class="section-title-box">
          <el-row class="section-title-item">
            <el-col :span="10" class="section-title">今日热销榜:</el-col>
            <el-col
              :span="2"
              :offset="12"
              class="section-title-icon el-icon-d-arrow-right com-right"
            ></el-col>
          </el-row>
        </el-row>

        <el-row class="section-content">
          <el-col
            :span="6"
            v-for="(item,index) in todayHotGoods"
            :key="index"
            class="goods-item iphone5-24"
          >
            <GoodsList :goods-info="item"/>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import GoodsList from "@/components/GoodsList.vue";
export default {
  asyncData({ store, route }) {
    return Promise.all([
      store.dispatch("superClassify"),
      store.dispatch("todayTjList", { page: 1, offset: 12 }),
      store.dispatch("swiperList", { type: "pc001", page: 1 }),
      store.dispatch("newInfoList", { cid: 0, page: 1, offset: 10 }),
      store.dispatch("todayHotGoods", {
        cid: 0,
        sale_type: 2,
        page: 1,
        offset: 20
      })
    ]);
  },
  computed: {
    superClassify() {
      let superClassify = this.$store.state.superClassify.list;
      return superClassify;
    },
    todayTjList() {
      let todayTjList = this.$store.state.todayTjList.list;
      return todayTjList;
    },
    swiperList() {
      let swiperList = this.$store.state.swiperList.list;
      return swiperList;
    },
    newInfoList() {
      let newInfoList = this.$store.state.newInfoList.list;
      if(newInfoList){
          newInfoList = newInfoList.slice(0, 9);
      }
      return newInfoList;
    },

    todayHotGoods() {
      let todayHotGoods = this.$store.state.todayHotGoods.list;
      return todayHotGoods;
    }
  },
  components: {
    GoodsList
  },
  data() {
    return {
      hostUrl: this.$api.hostUrl,
      tabPosition: "left"
    };
  },
  mounted: function() {
    this.$store.dispatch("superClassify");
    this.$store.dispatch("todayTjList", { page: 1, offset: 12 });
    this.$store.dispatch("swiperList", { type: "pc001", page: 1 });
    this.$store.dispatch("newInfoList", { cid: 0, page: 1, offset: 10 });
    this.$store.dispatch("todayHotGoods", {
      cid: 0,
      sale_type: 2,
      page: 1,
      offset: 20
    });
  },
  created: function() {

  },
  methods: {
    getGoodsByCate(cid, son_name) {
      this.$router.push({
        name: "HAllGoods",
        query: { cid: cid, keyword: son_name }
      });
    },
     goDetail(id) {
      this.$router.push({ name: "HTalentDetail", query: { id: id } });
    }
  }
};
</script>