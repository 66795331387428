import Vue from 'vue';
import Router from 'vue-router';
import Home from './pages/Home.vue';
import HQiang from './pages/HQiang.vue';
import HTalent from './pages/HTalent.vue';
import HAllGoods from './pages/HAllGoods.vue';
import HHotGoods from './pages/HHotGoods.vue';
import HGoodsDetail from './pages/HGoodsDetail.vue';
import HTalentDetail from './pages/HTalentDetail.vue';
import TGoodsList from './pages/TGoodsList.vue';

import HBrandGoods from './pages/HBrandGoods.vue';
import HBrandDetail from './pages/HBrandDetail.vue';

Vue.use(Router);

export function createRouter () {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/HQiang',
        name: 'HQiang',
        component: HQiang
      },
      {
        path: '/HHotGoods',
        name: 'HHotGoods',
        component: HHotGoods
      },
      {
        path: '/HBrandGoods',
        name: 'HBrandGoods',
        component: HBrandGoods
      },
      {
        path: '/HBrandDetail',
        name: 'HBrandDetail',
        component: HBrandDetail
      },
      {
        path: '/HTalent',
        name: 'HTalent',
        component: HTalent
      },
      {
        path: '/HTalentDetail',
        name: 'HTalentDetail',
        component: HTalentDetail
      },
      {
        path: '/HAllGoods',
        name: 'HAllGoods',
        component: HAllGoods
      },
      {
        path: '/HGoodsDetail',
        name: 'HGoodsDetail',
        component: HGoodsDetail
      },
      {
        path: '/TGoodsList',
        name: 'TGoodsList',
        component: TGoodsList
      },
      // {
      //   path: '/about',
      //   name: 'about',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ './pages/About.vue')
      // }
    ]
  })
}


