<template>
  <div>
    <el-row class="main-content">
      <el-col :span="22" :offset="1">
        <el-row>
          <el-col class="com-pd10">
            <el-row>
              <el-row class="brand-menu">
                <el-col class="brand-wrap">
                  <el-row>
                    <el-col class="brand-uppage" :span="1">
                      <span class="page-button" @click="brandUpPage">上一页</span>
                    </el-col>
                    <el-col class="brand-logo-list">
                      <el-col
                        v-for="(item,index) in brandList"
                        :key="index"
                        :span="2"
                        class="brand-logo-img"
                      >
                        <span @click="goBrandDetail(item.id)">
                          <img v-lazy="item.brand_logo" :key="item.brand_logo">
                        </span>
                      </el-col>
                    </el-col>
                    <el-col class="brand-nextPage" :span="1">
                      <span class="page-button" @click="brandNextPage">下一页</span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-row>
          </el-col>
        </el-row>

        <!-- 商品列表 -->
        <el-row class="section">
          <el-row class="section-title-box">
            <el-row class="section-title-item">
              <el-col :span="10" class="section-title">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/hBrandGoods' }">超值大牌</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col
                :span="2"
                :offset="12"
                class="section-title-icon el-icon-d-arrow-right com-right"
              ></el-col>
            </el-row>
          </el-row>
          <el-row class="data-list">
            <el-col :span="22"  class="iphone5-24">
              <el-row
                class="section-content com-mb30"
                v-for="(item,index) in brandsAndGoods"
                :key="index"
              >
                <el-col :span="6" class="border-shadow iphone5-24  iphone5-6 ">
                  <span @click="goBrandDetail(item.id)">
                    <el-row class="brand-box com-hover">
                      <el-col :span="24" class="brand-img com-center">
                        <img v-lazy="item.brand_logo+'&_200x200.jpg'" :key="item.brand_logo">
                      </el-col>
                      <el-col :span="24" class="brand-info">{{item.introduce}}</el-col>
                      <el-col :span="24" class="brand-more">更多商品>></el-col>
                    </el-row>
                  </span>
                </el-col>
                <el-col :span="18" class="border-shadow iphone5-24 iphone5-18">
                  <el-row>
                    <el-col
                      :span="8"
                      v-for="item in item.item"
                      :key="item.index"
                      class="goods-item iphone5-24 iphone5-12" 
                    >
                      <GoodsList :goods-info="item"/>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="4"></el-col>
          </el-row>
          <el-row class="data-page-box">
            <span class="data-page-botton" @click="dataUpPage">上一页</span>
            <span class="data-page-botton" @click="dataNextPage">下一页</span>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import GoodsList from "@/components/GoodsList.vue";
export default {
  asyncData({ store, route }) {
    let params = route.query;
    this.brandsAndGoodsPage = params.page || 1;

    let req = [
      store.dispatch("hBrandList", { page: 1, offset: 18 }),
      store.dispatch("hBrandsAndGoods", {
        cid:0,
        page: this.brandGoodsPage,
        offset: 10
      })
    ];
    return Promise.all(req);
  },
  computed: {
    brandList() {
      let brandList = this.$store.state.hBrandList.list;
      return brandList;
    },
    brandsAndGoods() {
      let hBrandsAndGoods = this.$store.state.hBrandsAndGoods.list;
      return hBrandsAndGoods;
    },
    brandsAndGoodsPage(){
       let page = this.$store.state.hBrandsAndGoods.page || 1;
      return page;
    },
    brandPage() {
      let page = this.$store.state.hBrandList.page || 1;
      return page;
    },
  },
  components: {
    GoodsList
  },
  data() {
    return {
      hostUrl: this.$api.hostUrl,
    };
  },
  created:function(){},
  mounted: function() {
    let params = this.$route.query;
    if (params.page) {
      this.brandsAndGoodsPage = params.page;
    }
    let _this = this;
    let brandPageNum = 1;
    let brandsAndGoodsPage = this.brandsAndGoodsPage;

    _this.getBrandList(brandPageNum);
    _this.getBrandsAndGoods(brandsAndGoodsPage);

  },
  methods: {
    getBrandList(page) {
       this.$store.dispatch("hBrandList", { page:page, offset: 18 });
    },
    brandUpPage() {
      let upPage = this.brandPage - 2;
      if (upPage > 0) {
        this.getBrandList(upPage);
      }
    },
    brandNextPage() {
      let nextPage = this.brandPage;
      if (nextPage < 4) {
        this.getBrandList(nextPage);
      }
    },
    getBrandsAndGoods(page) {
      this.$store.dispatch("hBrandsAndGoods", {
        cid:0,
        page: this.brandsAndGoodsPage,
        offset: 10
      })
    },
    dataUpPage() {
      let upPage = this.brandsAndGoodsPage - 2;
      console.log("brandsAndGoodsPage", upPage);
      if (upPage > 0) {
        this.$router.push({ name: "HBrandGoods", query: { page: upPage } });
        this.getBrandsAndGoods(upPage);
      }
    },
    dataNextPage() {
      let nextPage = this.brandsAndGoodsPage;
      this.$router.push({ name: "HBrandGoods", query: { page: nextPage } });
      this.getBrandsAndGoods(nextPage);
    },
    goBrandDetail(id) {
       this.brandPage = 1;
      this.$router.push({ name: "HBrandDetail", query: { id: id } });
    }
  }
};
</script>