import { createApp } from './main'

// 客户端特定引导逻辑……

// 这里假定 App.vue 模板中根元素具有 `id="app"`
const { app, router, store } = createApp()

// prime the store with server-initialized state.
// the state is determined during SSR and inlined in the page markup.
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
  app.$mount('#app')
})
